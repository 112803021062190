import React from 'react';

import { eventExplorePropTypes, surveyPropTypes } from 'helpers/proptypes';
import Facilitators from './Facilitators';
import Dates from './Dates';

const SurveyHeader = ({ survey, event }) => (
  <div className="question-block">
    <div>
      <ul className="list-unstyled">
        <li className="img-link">
          <div className="card">
            <a href="https://www.rid.org/" target="_blank">
              <img
                src="/static/images/RID.png"
                alt="RID Sponsored Credit"
                className="img-responsive"
              />
            </a>
          </div>
        </li>
        <li className="img-link">
          <div className="card">
            <a href="https://www.rid.org/continuing-education/acet/" target="_blank">
              <img
                src="/static/images/ACET.png"
                alt="ACET Sponsored Credit"
                className="img-responsive"
              />
            </a>
          </div>
        </li>
        <li className="img-link pull-right">
          <div className="card daspdp-logo">
            <img
              src="/static/images/2019-PDP-Logo_NoText.png"
              alt="DAS PDP"
              className="img-responsive"
            />
          </div>
        </li>
      </ul>
    </div>
    <div className="center">
      <h1>{survey.name}</h1>
      <h2>{event.title}</h2>
      <h3>
        <Facilitators event={event} />
      </h3>
      <h3>
        <Dates event={event} />
      </h3>
    </div>
  </div>
);

SurveyHeader.propTypes = {
  survey: surveyPropTypes.isRequired,
  event: eventExplorePropTypes.isRequired,
};

export default SurveyHeader;
