import React, { Fragment } from 'react';
import { eventExplorePropTypes } from 'helpers/proptypes';

const Facilitators = ({ event }) => {
  return (
    <Fragment>
      {event.facilitators.length > 1 ? (
        <strong>Facilitators: </strong>
      ) : (
        <strong>Facilitator: </strong>
      )}
      {!event.facilitator_names && event.facilitators.length === 0 ? (
        <Fragment>None Assigned</Fragment>
      ) : (
        <Fragment>{event.facilitator_names}</Fragment>
      )}
    </Fragment>
  );
};

Facilitators.propTypes = {
  event: eventExplorePropTypes,
};

export default Facilitators;
