import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import isEqual from 'lodash/isEqual';
import ReactRouterPropTypes from 'react-router-prop-types';

import { failedAssertion } from 'helpers/reporting';
import { userPropTypes } from 'helpers/proptypes';
import {
  Events,
  Survey as SurveyAPI,
  Question,
  Answer,
  Result,
  notifySuccess,
  notifyError,
} from 'api';
import { EventDictionary } from 'helpers/dictionaries';
import { Alert, Loading } from 'components';
import SurveyHeader from './SurveyHeader';
import SurveyBody from './SurveyBody';

const { REG_STATUS, PUB_STATUS } = EventDictionary;

class Survey extends React.Component {
  static propTypes = {
    eventId: PropTypes.string.isRequired,
    surveyId: PropTypes.string.isRequired,
    user: userPropTypes.isRequired,
    history: ReactRouterPropTypes.history.isRequired,
  };

  state = {
    isLoading: true,
    hasErrored: false,
    surveySaving: false,
  };

  generateId = (question) => `${question.question_type}-${question.id}`;

  handleCompleteSurvey = async () => {
    this.setState({ completeButtonSaving: true });
    const { user } = this.props;
    const { survey, event } = this.state;
    let { result } = this.state;
    const gradePromise = this.getGrade(event.pk, user.pk);
    let questions = [];
    survey.blocks.forEach((block) =>
      block.questions.forEach((question) => {
        questions.push({ answer: !!(question.answer && question.answer.id), question });
      })
    );
    const missingQuestions = questions.filter((i) => i.answer === false);
    if (missingQuestions.length > 0) {
      this.setState({ completeButtonSaving: false });
      const missingQuestionNumbers = missingQuestions
        .map((q) => 'Q' + q.question.number)
        .join(', ');
      notifyError(
        {},
        `Please answer all questions to complete the survey. Missing ${missingQuestionNumbers}. Did you save your responses?`
      );
    } else {
      result = Result.patch(result.id, { final: true, completed: new Date().toISOString() });
      const hasGrade = await gradePromise;
      const gradeOpts = hasGrade
        ? { method: 'patchGrade', authorOrEditor: 'editor' }
        : { method: 'postGrade', authorOrEditor: 'author' };
      const gradeData = {
        event: event.pk,
        user: user.pk,
        [gradeOpts.authorOrEditor]: user.pk,
        evaluated: true,
      };
      const grade = Events[gradeOpts.method](
        event.pk,
        (hasGrade && hasGrade.pk) || gradeData,
        gradeData
      );
      Promise.all([result, grade])
        .then(([result, grade, ...rest]) =>
          this.props.history.push({
            pathname: window.URLS['pd:complete_survey'](event.pk, survey.id),
            state: { survey, result, user, grade },
          })
        )
        .catch((err) => {
          notifyError({}, 'Survey could not be submitted.');
          failedAssertion('Survey result is final but grade is missing evaluated mark.', err);
          this.setState({ hasErrored: true, completeButtonSaving: false });
          throw err;
        });
    }
  };

  handleSaveBlockClick = async (blockId, formData) => {
    this.setBlockToSaving(blockId);

    let { blocks } = this.state.survey;
    const block = this.state.survey.blocks.find((b) => b.id === blockId);
    const questions = await Promise.all(
      block.questions.map((question) => this.processAnswer(question, formData))
    );
    const updatedBlocks = blocks.map((block) => {
      if (block.id === blockId) {
        return { ...block, questions, isSaving: false };
      }
      return block;
    });
    this.setState((prevState, _) => ({ survey: { ...prevState.survey, blocks: updatedBlocks } }));

    notifySuccess('Responses have been saved!');
  };

  setBlockToSaving = (blockId) => {
    let { survey } = this.state;
    const blocks = survey.blocks.map((block) => {
      if (block.id === blockId) {
        return { ...block, isSaving: true };
      }
      return block;
    });
    this.setState({ survey: { ...survey, blocks } });
  };

  processAnswer = async (question, formData) => {
    let id = this.generateId(question);
    let response = { value: formData[id] };
    if (question.metadata && question.metadata.choiceMatrix) {
      response.value = question.metadata.choiceMatrix.labels.reduce((accum, label, idx) => {
        const matrixId = `${id}-${idx}`;
        return { ...accum, ...{ [label]: formData[matrixId] } };
      }, {});
    }
    const method = question.answer ? 'patch' : 'post';
    const authorOrEditor = question.answer ? 'editor' : 'author';
    const data = {
      question: question.id,
      survey: this.state.survey.id,
      [authorOrEditor]: this.props.user.pk,
      response,
    };
    const answerId = question.answer && question.answer.id;
    let answer = question.answer;
    if (this.answerChanged(data, question.answer)) {
      answer = await Answer[method](answerId || data, answerId && data);
    }
    return { ...question, answer };
  };

  answerChanged = (newAnswer, currentAnswer = {}) => {
    if (!currentAnswer) {
      return true;
    }
    const { question, survey, response } = currentAnswer;
    let { author, editor, ...answer } = newAnswer;
    return !isEqual(answer, { question, survey, response });
  };

  processQuestionBlock = async (block) => {
    const promises = block.questions.map((questionId) => Question.get(questionId));
    let questions = await Promise.all(promises);
    questions = questions.map((question) => {
      const answer = question.answers && question.answers.find((answer) => answer.id);
      return { ...question, answer };
    });
    questions = questions.sort((a, b) =>
      a.order_id && b.order_id ? (a.order_id < b.order_id ? 0 : 1) : a.number < b.number ? 0 : 1
    );
    return { ...block, questions };
  };

  getSurvey = async (surveyId) => {
    const response = await SurveyAPI.get(surveyId);
    const { question_blocks: questionBlocks, ...rest } = response;
    let blocks = await Promise.all(questionBlocks.map(this.processQuestionBlock));
    blocks = blocks.sort((a, b) => (a.order_id < b.order_id ? -1 : 1));
    return { ...rest, blocks };
  };

  getEvent = async (eventId) => Events.exploreDetail(eventId);

  getRegistrations = async (eventId) =>
    Events.registrationList(eventId).then((registrations) =>
      registrations.filter((reg) => reg.status === REG_STATUS.APPROVED)
    );

  getResults = async () => Result.list();

  getGrade = async (eventId, userId) => {
    const data = await Events.gradeList(eventId);
    const grade = data.find((g) => g.user === userId);
    return grade;
  };

  createResult = async (surveyId) =>
    Result.post({ author: this.props.user.pk, survey: surveyId }).then((result) =>
      this.setState({ result })
    );

  componentWillUnmount() {
    this.mounted = false;
  }

  async componentDidMount() {
    this.mounted = true;
    const { surveyId, eventId, user } = this.props;
    let survey, regs, event, results;
    try {
      [survey, regs, event, results] = await Promise.all([
        this.getSurvey(surveyId),
        this.getRegistrations(eventId),
        this.getEvent(eventId),
        this.getResults(),
      ]);
    } catch (err) {
      this.setState({ hasErrored: true, isLoading: false });
      throw err;
    }
    if (survey.publication_status === PUB_STATUS.DRAFT || moment().isAfter(moment(survey.end))) {
      this.setState({
        errorMessage: 'Sorry, this survey has closed and is no longer accepting submissions.',
        hasErrored: true,
      });
      return;
    }
    let result =
      results && results.find((r) => r.survey === parseInt(surveyId) && r.author === user.pk);
    if (!result) {
      this.createResult(surveyId);
    }
    if (result && result.final) {
      let grade;
      try {
        grade = await this.getGrade(event.pk, user.pk);
        if (!grade.evaluated) {
          grade = await Events.patchGrade(event.pk, grade.pk, { evaluated: true });
        }
      } catch (err) {
        failedAssertion('Error retrieving Grade data', err);
        this.setState({
          hasErrored: true,
          errorMessage: 'Error retrieving data. Please try again later.',
        });
        return;
      }
      try {
        const gradeData = {
          event: event.pk,
          user: user.pk,
          author: user.pk,
          evaluated: true,
        };
        if (!grade) {
          grade = await Events.postGrade(event.pk, gradeData);
        }
        if (!grade.evaluated) {
          failedAssertion('Survey result is final but grade is missing evaluated mark.', grade);
          this.setState({
            hasErrored: true,
            errorMessage:
              'Uh oh! It looks like there was an issue with your evaluation not being recorded. We have been notified and will look into the issue.',
          });
          return;
        }
        this.props.history.push({
          pathname: window.URLS['pd:complete_survey'](event.pk, survey.id),
          state: { survey, result, user, grade },
        });
      } catch (err) {
        failedAssertion('Survey something whent wrong with grades.', err);
        this.setState({
          errorMessage: 'Grades cannot be modified more than 30 days after the event concludes.',
          hasErrored: true,
        });
        return;
      }
    }
    if (!regs.find((reg) => reg.user === user.pk)) {
      this.setState({
        errorMessage:
          'You are not authorized to take this survey. Only registered attendees may participate.',
        hasErrored: true,
      });
      return;
    }
    if (this.mounted) {
      this.setState({
        survey,
        event,
        result,
        isLoading: false,
      });
    }
  }

  render() {
    const { survey, event, surveySaving, isLoading, hasErrored, errorMessage } = this.state;

    if (hasErrored) {
      return (
        <Alert type="danger" icon="fa-exclamation-triangle">
          {errorMessage || 'An error occurred. Please try and reload the page or try again later.'}
        </Alert>
      );
    }

    if (isLoading) return <Loading />;

    return (
      <Fragment>
        <SurveyHeader survey={survey} event={event} />
        <SurveyBody
          survey={survey}
          surveySaving={surveySaving}
          generateId={this.generateId}
          onSaveBlockClick={this.handleSaveBlockClick}
          onCompleteSurveyClick={this.handleCompleteSurvey}
        />
      </Fragment>
    );
  }
}

export default Survey;
