import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactRouterPropTypes from 'react-router-prop-types';

import { Alert, Loading } from 'components';
import { requestCurrentUser } from 'store/actions/accounts/user';
import { userPropTypes } from 'helpers/proptypes';
import Survey from './Survey';

class SurveyPage extends React.Component {
  static propTypes = {
    user: userPropTypes,
    isLoading: PropTypes.bool.isRequired,
    hasErrored: PropTypes.bool.isRequired,
    match: ReactRouterPropTypes.match.isRequired,
    history: ReactRouterPropTypes.history.isRequired,
    requestCurrentUser: PropTypes.func.isRequired,
  };

  static defaultProps = {
    hasErrored: false,
    isLoading: true,
  };

  state = {
    isLoading: this.props.isLoading,
    hasErrored: this.props.hasErrored,
  };

  componentDidMount() {
    this.props.requestCurrentUser();
  }

  render() {
    if (this.props.hasErrored) {
      return (
        <Alert type="danger" icon="fa-exclamation-triangle">
          An error occurred. Please try and reload the page.
        </Alert>
      );
    }

    if (this.props.isLoading) {
      return <Loading />;
    }

    const { user } = this.props;
    const { surveyId, eventId } = this.props.match.params;

    return (
      <div className="col-xs-12 col-md-10 col-md-offset-1 col-lg-8 col-lg-offset-2 survey">
        <Survey
          user={user}
          surveyId={surveyId}
          eventId={eventId}
          history={this.props.history}
          onSaveBlockClick={this.handleSaveBlockClick}
          onCompleteSurveyClick={this.handleCompleteSurvey}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const user = state.accountsUser[state.accountsUser.current];
  const isLoading = state.accountsUser.isLoading;
  const hasErrored = state.accountsUser.hasErrored;
  return {
    user,
    isLoading,
    hasErrored,
  };
};

export default connect(
  mapStateToProps,
  { requestCurrentUser }
)(SurveyPage);
