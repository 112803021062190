import React, { Fragment } from 'react';
import { eventExplorePropTypes } from 'helpers/proptypes';

const Dates = ({ event }) => {
  const times = event.times.length > 0 ? event.times : [['No schedule at this time', []]];
  return (
    <Fragment>
      {times.map((time, index) => (
        <div key={index}>
          <p>
            <strong>
              Meeting Schedule
              {times.length > 1 ? ` (${index + 1})` : null}
            </strong>
          </p>
          <div className="event-block__line">
            <span className="event-block__line-icon fa fa-clock-o fa-fw" />
            {time[0]}
          </div>
          <div className="event-block__line">
            <span className="event-block__line-icon fa fa-map-marker fa-fw" />
            {time[2] ? time[2] : 'No Location Assigned'}
          </div>
        </div>
      ))}
    </Fragment>
  );
};

Dates.propTypes = {
  event: eventExplorePropTypes,
};

export default Dates;
