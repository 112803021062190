import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { surveyPropTypes } from 'helpers/proptypes';
import QuestionBlock from 'components/survey-admin/detail-view/QuestionBlock';
import CompleteSurveyButton from './CompleteSurveyButton';

const SurveyBody = ({
  survey,
  surveySaving,
  generateId,
  onSaveBlockClick,
  onCompleteSurveyClick,
}) => {
  return (
    <Fragment>
      {survey.blocks.map((block) => (
        <QuestionBlock
          key={`block-${block.id}`}
          block={block}
          onClick={onSaveBlockClick}
          generateId={generateId}
        />
      ))}
      <div className="survey question-block center">
        <CompleteSurveyButton onClick={onCompleteSurveyClick} saving={surveySaving} />
      </div>
    </Fragment>
  );
};

SurveyBody.propTypes = {
  survey: surveyPropTypes.isRequired,
  surveySaving: PropTypes.bool,
  generateId: PropTypes.func.isRequired,
  onSaveBlockClick: PropTypes.func.isRequired,
  onCompleteSurveyClick: PropTypes.func.isRequired,
};

export default SurveyBody;
