import React from 'react';
import PropTypes from 'prop-types';

const CompleteSurveyButton = (props) => (
  <button
    className="btn btn-success btn-block"
    onClick={props.onClick}
    type="button"
    disabled={props.saving}
  >
    {props.saving ? <i className="fa fa-circle-o-notch fa-spin" /> : 'Submit Survey'}
  </button>
);

CompleteSurveyButton.defaultProps = {
  onClick: () => {},
  saving: false,
};

CompleteSurveyButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,
};

export default CompleteSurveyButton;
